import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { Loader } from "semantic-ui-react"

export default function CommonModal({
  open = true,
  modalHeader = "",
  modalText = "",
  dismissButton = true,
  dismissButtonLabel = "Cancel",
  actionButton = true,
  actionButtonLabel = "Submit",
  isActionLoading = false,
  actionButtonDisabled = false,
  noActions = false,
  maxWidth = undefined,
  isCustomBtnStyle = "btn btn-purple-round",
  actionButtonClass = "",
  onDismiss = function () {
    return
  },
  onAction = function () {
    return
  },
  ...props
}) {
  const widthProps = {
    maxWidth,
    fullWidth: props.noFullWidth ? false : true,
  }
  return (
    <>
      <Dialog {...widthProps} open={open} onClose={onDismiss}>
        {props?.closeButton && (
          <div className="modal-header" style={{ border: "0px" }}>
            <button
              onClick={onDismiss}
              type="button"
              className="close"
              style={{ paddingTop: "7px", paddingRight: "10px" }}
            >
              <img src="images/icon_close.svg" alt="Close" width={18} />
            </button>
          </div>
        )}
        {modalHeader && (
          <div className="common__modal-title">
            <DialogTitle className="common__modal-title-text text-center">
              {modalHeader}
            </DialogTitle>
          </div>
        )}

        {props.customContent ? (
          <>
            {typeof modalText === "object" ? (
              <>{modalText}</>
            ) : (
              <DialogContentText className="common__modal-text text-center">
                {modalText}
              </DialogContentText>
            )}
            {/* <DialogActions> */}
            {noActions ? null : (
              <div className={`common__modal-footer modal-footer ${actionButtonClass}`}>
                {dismissButton && (
                  <button
                    type="button"
                    className="sec-btn"
                    onClick={onDismiss}
                    disabled={isActionLoading}
                  >
                    {dismissButtonLabel}
                  </button>
                )}
                &nbsp;&nbsp;&nbsp;
                {actionButton && (
                  <button
                    type="button"
                    className="btn btn-purple-round"
                    onClick={onAction}
                    disabled={isActionLoading}
                  >
                    {actionButtonLabel}
                    {isActionLoading ? (
                      <>
                        &nbsp;
                        <Loader
                          active={isActionLoading}
                          inline
                          inverted
                          size="tiny"
                        />
                      </>
                    ) : null}
                  </button>
                )}
              </div>
            )}

            {/* </DialogActions> */}
          </>
        ) : (
          <DialogContent className="common__modal">
            {typeof modalText === "object" ? (
              <>{modalText}</>
            ) : (
              <DialogContentText className="common__modal-text text-center">
                {modalText}
              </DialogContentText>
            )}
            {/* <DialogActions> */}
            {noActions ? null : (
              <div className={"common__modal-footer modal-footer"}>
                {dismissButton && (
                  <button
                    type="button"
                    className="btn btn-red-round-bdr"
                    onClick={onDismiss}
                    disabled={isActionLoading}
                  >
                    {dismissButtonLabel}
                  </button>
                )}

                {actionButton && (
                  <button
                    type="button"
                    className={isCustomBtnStyle ? isCustomBtnStyle : "btn btn-purple-round"}
                    onClick={onAction}
                    disabled={isActionLoading}
                  >
                    {actionButtonLabel}
                    {isActionLoading ? (
                      <>
                        &nbsp;
                        <Loader
                          active={isActionLoading}
                          inline
                          inverted
                          size="tiny"
                        />
                      </>
                    ) : null}
                  </button>
                )}
              </div>
            )}

            {/* </DialogActions> */}
          </DialogContent>
        )}
      </Dialog>
      <style jsx>
        {`
          .common__modal {
            padding: 2rem 0;
          }
          .common__modal-footer {
            border: none !important;
            text-align: center;
            margin: 1rem 0 !important;
          }
          .common__modal-title h1, h2, h3, h4, h5{
            line-height:1.5 !important;
          }
          .common__modal-title {
            margin-top: 2rem !important;
          }
          .common__modal-title-text {
            margin-top: 1rem !important;
          }
          .common__modal-text {
            color: black !important;
          }
          .common__modal-action {
            margin-left: 2rem !important;
          }
        `}
      </style>
    </>
  )
}
