import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";

const TAG = "deleteUserAggregator.js";

function* workerDeleteUserAggregator(payLoad) {
  console.log(TAG, "Delete User : " + JSON.stringify(payLoad));
  try {
    const response = yield call(Api.makeSecuredPostRequest, API_ENDPOINT.DELETE_USER_AGGREGATOR + payLoad.data);
    yield put(memberAction.createActionOf(memberType.DELETE_USER_SUCCESS_AGGREGATOR, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get country failed: " + error.message);
    yield put(memberAction.createActionOf(memberType.DELETE_USER_FAILED_AGGREGATOR, 2, error));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchDeleteUserAggregator = takeLatest(memberType.DELETE_USER_REQUESTED_AGGREGATOR, workerDeleteUserAggregator);
