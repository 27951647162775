import { LOADING } from "../../../constants/constant";
import { DELETE_USER_FAILED_AGGREGATOR, DELETE_USER_REQUESTED_AGGREGATOR, DELETE_USER_RESET_AGGREGATOR, DELETE_USER_SUCCESS_AGGREGATOR } from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getDeleteUserAggregatorReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_REQUESTED_AGGREGATOR:
      let reqstState = Object.assign({}, state);
      reqstState.deleteUserisLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DELETE_USER_SUCCESS_AGGREGATOR:
      let userRes = Object.assign({}, state);
      console.log("DELETE user reducer => Case => Success:aggregator " + JSON.stringify(action.data));
      userRes.deleteUserStatus = action.data.status_message;
      userRes.deleteUserStatusCode = action.data.status_code;
      userRes.deleteUserisLoading = false;
      // userRes.reInviteUserMessage = action.data.message;
      // userRes.deleteUserData = action.data.data;

      return userRes;

    case DELETE_USER_FAILED_AGGREGATOR:
      console.error("DELETE User Reducer => Case => Error:aggregator " + JSON.stringify(action.error));
      return Object.assign({}, state, action.error);

    case DELETE_USER_RESET_AGGREGATOR: {
      return initialState;
    }

    default:
      return state;
  }
}
