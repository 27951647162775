import { put, takeLatest, call } from "redux-saga/effects";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getAllUsersAggregatorSaga.js";

function* workerGetAllUseresAggregator(payLoad) {
  console.log(TAG, "get all Users : " + JSON.stringify(payLoad));
  try {
    const response = yield call(Api.makeSecuredGetRequest, API_ENDPOINT.GET_ALL_USERS_AGGREGATOR);
    console.log("respo in getallusersaggregator saga :", response);
    yield put(createActionOf(memberType.GET_ALL_USERS_SUCCESS_AGGREGATOR, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get All Users failed: " + error.message);
    yield put(createActionOf(memberType.GET_ALL_USERS_FAILED_AGGREGATOR, 2, error));
    // throw new Error("Error: inside saga", JSON.stringify(error));
  }
}

export const watchGetAllUsersAggregator = takeLatest(memberType.GET_ALL_USERS_REQUESTED_AGGREGATOR, workerGetAllUseresAggregator);
