import { LOADING } from "../../../constants/constant";
import { GET_ALL_USERS_FAILED_AGGREGATOR, GET_ALL_USERS_SUCCESS_AGGREGATOR, GET_ALL_USERS_REQUESTED_AGGREGATOR, GET_ALL_USERS_RESET_AGGREGATOR } from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getAllUsersAggregatorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_REQUESTED_AGGREGATOR:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_ALL_USERS_SUCCESS_AGGREGATOR:
      let userRes = Object.assign({}, state);
      console.log("get All Users in getAllUsersAggregator reducer=> Case => Success:" + JSON.stringify(action.data));
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.data = action.data.data?.data;

      return userRes;

    case GET_ALL_USERS_FAILED_AGGREGATOR:
      console.error("get all users Reducer => Case => Failed:" + JSON.stringify(action.error));
      let errorRes = Object.assign({}, state, action.data);
      errorRes.error=action.data
      errorRes.isLoading = false;
      console.error("Error while sending message", action.data);
      return errorRes;

    case GET_ALL_USERS_RESET_AGGREGATOR: {
      return initialState;
    }

    default:
      return state;
  }
}
