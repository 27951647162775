import { LOADING } from "../../../constants/constant";
import { REINVITE_USER_FAILED_AGGREGATOR, REINVITE_USER_REQUESTED_AGGREGATOR, REINVITE_USER_RESET_AGGREGATOR, REINVITE_USER_SUCCESS_AGGREGATOR } from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getReInviteUserAggregatorReducer(state = initialState, action) {
  switch (action.type) {
    case REINVITE_USER_REQUESTED_AGGREGATOR:
      let reqstState = Object.assign({}, state);
      reqstState.reInviteUserIsLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case REINVITE_USER_SUCCESS_AGGREGATOR:
      let userRes = Object.assign({}, state);
      console.log("REInvite user reducer => Case => Success:aggregator :" + JSON.stringify(action.data));
      userRes.reInviteUserStatus = action.data.status_message;
      userRes.reInviteUserStatusCode = action.data.status_code;
      userRes.reInviteUserIsLoading = false;
      userRes.reInviteUserMessage = action.data.message;
      userRes.reInviteUserData = action.data.data;

      return userRes;

    case REINVITE_USER_FAILED_AGGREGATOR:
      console.error("Invite User Reducer => Case => Error:Aggregator " + JSON.stringify(action.error));
      return Object.assign({}, state, action.error);

    case REINVITE_USER_RESET_AGGREGATOR: {
      return initialState;
    }

    default:
      return state;
  }
}
