export * from "./campaignActions";
export * from "./newAggregatorActions";
export * from "./aggregatorBilling";

export const GET_BOT_LIST_REQUESTED = "GET_BOT_LIST_REQUESTED";
export const GET_BOT_LIST_SUCCESS = "GET_BOT_LIST_SUCCESS";
export const GET_BOT_LIST_FAILED = "GET_BOT_LIST_FAILED";

export const GET_AGGREGATOR_LIST = "GET_AGGREGATOR_LIST";
export const GET_AGGREGATOR_LIST_SUCCESS = "GET_AGGREGATOR_LIST_SUCCESS";
export const GET_AGGREGATOR_LIST_FAILED = "GET_AGGREGATOR_LIST_FAILED";

export const GET_CATEGORY_LIST_REQUESTED = "GET_CATEGORY_LIST_REQUESTED";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILED = "GET_CATEGORY_LIST_FAILED";

export const GET_SUMMARY_CARDS = "GET_SUMMARY_CARDS";
export const GET_SUMMARY_CARDS_FAILED = "GET_SUMMARY_CARDS_FAILED";
export const GET_SUMMARY_CARDS_SUCCESS = "GET_SUMMARY_CARDS_SUCCESS";

export const GET_CARRIER_LIST_REQUESTED = "GET_CARRIER_LIST_REQUESTED";
export const GET_CARRIER_LIST_SUCCESS = "GET_CARRIER_LIST_SUCCESS";
export const GET_CARRIER_LIST_FAILED = "GET_CARRIER_LIST_FAILED";

export const GET_BRANDS_LIST_REQUESTED = "GET_BRANDS_LIST_REQUESTED";
export const GET_BRANDS_LIST_SUCCESS = "GET_BRANDS_LIST_SUCCESS";
export const GET_BRANDS_LIST_FAILED = "GET_BRANDS_LIST_FAILED";

export const GET_COUNTRY_LIST_REQUESTED = "GET_COUNTRY_LIST_REQUESTED";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAILED = "GET_COUNTRY_LIST_FAILED";

export const VERIFY_EMAIL_REQUESTED = "VERIFY_EMAIL_REQUESTED";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "VERIFY_EMAIL_FAILED";
export const VERIFY_EMAIL_RESET = "VERIFY_EMAIL_RESET";

export const SET_PASSWORD_REQUESTED = "SET_PASSWORD_REQUESTED";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";

export const SETUP_USER_DETAILS_REQUESTED = "SETUP_USER_DETAILS_REQUESTED";
export const SETUP_USER_DETAILS_SUCCESS = "SETUP_USER_DETAILS_SUCCESS";
export const SETUP_USER_DETAILS_FAILED = "SETUP_USER_DETAILS_FAILED";
export const SETUP_USER_DETAILS_RESET = "SETUP_USER_DETAILS_RESET";

export const GET_DASHBOARD_ANALYTICS = "GET_DASHBOARD_ANALYTICS";
export const GET_DASHBOARD_ANALYTICS_SUCCESS = "GET_DASHBOARD_ANALYTICS_SUCCESS";
export const GET_DASHBOARD_ANALYTICS_FAILED = "GET_DASHBOARD_ANALYTICS_FAILED";
export const GET_DASHBOARD_ANALYTICS_RESET = "GET_DASHBOARD_ANALYTICS_RESET";
export const GRAPH_DATA_REQUESTED = "GRAPH_DATA_REQUESTED";
export const GRAPH_DATA_SUCCESS = "GRAPH_DATA_SUCCESS";
export const GRAPH_DATA_FAILED = "GRAPH_DATA_FAILED";

export const SETUP_ENTERPRISE_DETAILS_REQUESTED = "SETUP_ENTERPRISE_DETAILS_REQUESTED";
export const SETUP_ENTERPRISE_DETAILS_SUCCESS = "SETUP_ENTERPRISE_DETAILS_SUCCESS";
export const SETUP_ENTERPRISE_DETAILS_FAILED = "SETUP_ENTERPRISE_DETAILS_FAILED";
export const SETUP_ENTERPRISE_DETAILS_RESET = "SETUP_ENTERPRISE_DETAILS_RESET";

export const PARTNER_DETAILS_REQUESTED = "PARTNER_DETAILS_REQUESTED";
export const PARTNER_DETAILS_SUCCESS = "PARTNER_DETAILS_SUCCESS";
export const PARTNER_DETAILS_FAILED = "PARTNER_DETAILS_FAILED";
export const PARTNER_DETAILS_RESET = "PARTNER_DETAILS_RESET";

export const BOT_LIST_REQUESTED = "BOT_LIST_REQUESTED";
export const BOT_LIST_SUCCESS = "BOT_LIST_SUCCESS";
export const BOT_LIST_FAILED = "BOT_LIST_FAILED";
export const BOT_LIST_RESET = "BOT_LIST_RESET";

export const CATEGORY_LIST_REQUESTED = "CATEGORY_LIST_REQUESTED";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILED = "CATEGORY_LIST_FAILED";

export const CARRIER_LIST_REQUESTED = "CARRIER_LIST_REQUESTED";
export const CARRIER_LIST_SUCCESS = "CARRIER_LIST_SUCCESS";
export const CARRIER_LIST_FAILED = "CARRIER_LIST_FAILED";

export const COUNTRY_LIST_REQUESTED = "COUNTRY_LIST_REQUESTED";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAILED = "COUNTRY_LIST_FAILED";

export const GET_BRANDS_BY_PROFILE_ID_REQUESTED = "GET_BRANDS_BY_PROFILE_ID_REQUESTED";
export const GET_BRANDS_BY_PROFILE_ID_SUCCESS = "GET_BRANDS_BY_PROFILE_ID_SUCCESS";
export const GET_BRANDS_BY_PROFILE_ID_FAILED = "GET_BRANDS_BY_PROFILE_ID_FAILED";
export const SUBMIT_RCS_BOT = "SUBMIT_RCS_BOT";
export const SUBMIT_RCS_BOT_SUCCESS = "SUBMIT_RCS_BOT_SUCCESS";
export const SUBMIT_RCS_BOT_FAILED = "SUBMIT_RCS_BOT_FAILED";
export const SUBMIT_RCS_BOT_RESET = "SUBMIT_RCS_BOT_RESET";

export const SUBMIT_WHATSAPP_BOT = "SUBMIT_WHATSAPP_BOT";
export const SUBMIT_WHATSAPP_BOT_SUCCESS = "SUBMIT_WHATSAPP_BOT_SUCCESS";
export const SUBMIT_WHATSAPP_BOT_FAILED = "SUBMIT_WHATSAPP_BOT_FAILED";
export const SUBMIT_WHATSAPP_BOT_RESET = "SUBMIT_WHATSAPP_BOT_RESET";

export const UPDATE_RCS_BOT = "UPDATE_RCS_BOT";
export const UPDATE_RCS_BOT_SUCCESS = "UPDATE_RCS_BOT_SUCCESS";
export const UPDATE_RCS_BOT_FAILED = "UPDATE_RCS_BOT_FAILED";
export const UPDATE_RCS_BOT_RESET = "UPDATE_RCS_BOT_RESET";

export const UPDATE_WHATSAPP_BOT = "UPDATE_WHATSAPP_BOT";
export const UPDATE_WHATSAPP_BOT_SUCCESS = "UPDATE_WHATSAPP_BOT_SUCCESS";
export const UPDATE_WHATSAPP_BOT_FAILED = "UPDATE_WHATSAPP_BOT_FAILED";
export const UPDATE_WHATSAPP_BOT_RESET = "UPDATE_WHATSAPP_BOT_RESET";

export const GET_BRANDS_REQUESTED = "GET_BRANDS_REQUESTED";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILED = "GET_BRANDS_FAILED";

export const CONFIRM_RCS_SUBMITION = "CONFIRM_RCS_SUBMITION";
export const CONFIRM_RCS_SUBMITION_SUCCESS = "CONFIRM_RCS_SUBMITION_SUCCESS";
export const CONFIRM_RCS_SUBMITION_FAILED = "CONFIRM_RCS_SUBMITION_FAILED";
export const CONFIRM_RCS_SUBMITION_RESET = "CONFIRM_RCS_SUBMITION_RESET";

export const CONFIRM_WHATSAPP_SUBMITION = "CONFIRM_WHATSAPP_SUBMITION";
export const CONFIRM_WHATSAPP_SUBMITION_SUCCESS = "CONFIRM_WHATSAPP_SUBMITION_SUCCESS";
export const CONFIRM_WHATSAPP_SUBMITION_FAILED = "CONFIRM_WHATSAPP_SUBMITION_FAILED";
export const CONFIRM_WHATSAPP_SUBMITION_RESET = "CONFIRM_WHATSAPP_SUBMITION_RESET";

export const GET_BOT_DETAILS = "GET_BOT_DETAILS";
export const GET_BOT_DETAILS_SUCCESS = "GET_BOT_DETAILS_SUCCESS";
export const GET_BOT_DETAILS_FAILED = "GET_BOT_DETAILS_FAILED";
export const GET_BOT_DETAILS_RESET = "GET_BOT_DETAILS_RESET";

export const LAUNCH_SETUP_COMPANY_DETAILS = "LAUNCH_SETUP_COMPANY_DETAILS";
export const LAUNCH_SETUP_COMPANY_DETAILS_SUCCESS = "LAUNCH_SETUP_COMPANY_DETAILS_SUCCESS";
export const LAUNCH_SETUP_COMPANY_DETAILS_FAILED = "LAUNCH_SETUP_COMPANY_DETAILS_FAILED";
export const LAUNCH_SETUP_COMPANY_DETAILS_RESET = "LAUNCH_SETUP_COMPANY_DETAILS_RESET";

export const GET_LAUNCH_CARRIER = "GET_LAUNCH_CARRIER";
export const GET_LAUNCH_CARRIER_SUCCESS = "GET_LAUNCH_CARRIER_SUCCESS";
export const GET_LAUNCH_CARRIER_FAILED = "GET_LAUNCH_CARRIER_FAILED";
export const GET_LAUNCH_CARRIER_RESET_STATE = "GET_LAUNCH_CARRIER_RESET_STATE";

export const GET_INITIAL_DETAILS = "GET_INITIAL_DETAILS";
export const GET_INITIAL_DETAILS_SUCCESS = "GET_INITIAL_DETAILS_SUCCESS";
export const GET_INITIAL_DETAILS_FAILED = "GET_INITIAL_DETAILS_FAILED";

export const SUBMIT_LAUNCH_CARRIER = "SUBMIT_LAUNCH_CARRIER";
export const SUBMIT_LAUNCH_CARRIER_SUCCESS = "SUBMIT_LAUNCH_CARRIER_SUCCESS";
export const SUBMIT_LAUNCH_CARRIER_FAILED = "SUBMIT_LAUNCH_CARRIER_FAILED";
export const SUBMIT_LAUNCH_CARRIER_RESET = "SUBMIT_LAUNCH_CARRIER_RESET";

export const PARTNER_BRANDS_LIST_REQUESTED = "PARTNER_BRANDS_LIST_REQUESTED";
export const PARTNER_BRANDS_LIST_SUCCESS = "PARTNER_BRANDS_LIST_SUCCESS";
export const PARTNER_BRANDS_LIST_FAILED = "PARTNER_BRANDS_LIST_FAILED";

export const GET_ALL_INDUSTRY_TYPES_REQUESTED = "GET_ALL_INDUSTRY_TYPES_REQUESTED";
export const GET_ALL_INDUSTRY_TYPES_SUCCESS = "GET_ALL_INDUSTRY_TYPES_SUCCESS";
export const GET_ALL_INDUSTRY_TYPES_FAILED = "GET_ALL_INDUSTRY_TYPES_FAILED";

export const SUBMIT_BRAND_REQUESTED = "SUBMIT_BRAND_REQUESTED";
export const SUBMIT_BRAND_SUCCESS = "SUBMIT_BRAND_SUCCESS";
export const SUBMIT_BRAND_FAILED = "SUBMIT_BRAND_FAILED";
export const SUBMIT_BRAND_RESET = "SUBMIT_BRAND_RESET";

export const UPDATE_BRAND_REQUESTED = "UPDATE_BRAND_REQUESTED";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILED = "UPDATE_BRAND_FAILED";

export const GET_SINGLE_BRAND_REQUESTED = "GET_SINGLE_BRAND_REQUESTED";
export const GET_SINGLE_BRAND_SUCCESS = "GET_SINGLE_BRAND_SUCCESS";
export const GET_SINGLE_BRAND_FAILED = "GET_SINGLE_BRAND_FAILED";
export const GET_SINGLE_BRAND_RESET = "GET_SINGLE_BRAND_RESET";

export const GET_VERIFIED_LAUNCH_CARRIER = "GET_VERIFIED_LAUNCH_CARRIER";
export const GET_VERIFIED_LAUNCH_CARRIER_SUCCESS = "GET_VERIFIED_LAUNCH_CARRIER_SUCCESS";
export const GET_VERIFIED_LAUNCH_CARRIER_FAILED = "GET_VERIFIED_LAUNCH_CARRIER_FAILED";

export const SUBMIT_VERIFIED_CARRIERS = "SUBMIT_VERIFIED_CARRIERS";
export const SUBMIT_VERIFIED_CARRIERS_SUCCESS = "SUBMIT_VERIFIED_CARRIERS_SUCCESS";
export const SUBMIT_VERIFIED_CARRIERS_FAILED = "SUBMIT_VERIFIED_CARRIERS_FAILED";
export const SUBMIT_VERIFIED_CARRIERS_RESET = "SUBMIT_VERIFIED_CARRIERS_RESET";

export const SUBMIT_BOT_SUMMARY = "SUBMIT_BOT_SUMMARY";
export const SUBMIT_BOT_SUMMARY_SUCCESS = "SUBMIT_BOT_SUMMARY_SUCCESS";
export const SUBMIT_BOT_SUMMARY_FAILED = "SUBMIT_BOT_SUMMARY_FAILED";

export const GET_PARTNERS_CARRIERS = "GET_PARTNERS_CARRIERS";
export const GET_PARTNERS_CARRIERS_SUCCESS = "GET_PARTNERS_CARRIERS_SUCCESS";
export const GET_PARTNERS_CARRIERS_FAILED = "GET_PARTNERS_CARRIERS_FAILED";
export const GET_PARTNERS_CARRIERS_RESET_STATE = "GET_PARTNERS_CARRIERS_RESET_STATE";

export const RESET_STATE = "RESET_STATE";

export const GET_BOT_WHATSAPP_DETAILS = "GET_BOT_WHATSAPP_DETAILS";
export const GET_BOT_WHATSAPP_DETAILS_SUCCESS = "GET_BOT_WHATSAPP_DETAILS_SUCCESS";
export const GET_BOT_WHATSAPP_DETAILS_FAILED = "GET_BOT_WHATSAPP_DETAILS_FAILED";
export const GET_BOT_WHATSAPP_DETAILS_RESET = "GET_BOT_WHATSAPP_DETAILS_RESET";

export const UPDATE_PARTNERS_PROFILE_REQUESTED = "UPDATE_PARTNERS_PROFILE_REQUESTED";
export const UPDATE_PARTNERS_PROFILE_SUCCESS = "UPDATE_PARTNERS_PROFILE_SUCCESS";
export const UPDATE_PARTNERS_PROFILE_FAILED = "UPDATE_PARTNERS_PROFILE_FAILED";
export const UPDATE_PARTNERS_PROFILE_RESET = "UPDATE_PARTNERS_PROFILE_RESET";

export const GET_LAUNCH_SUMMARY = "GET_LAUNCH_SUMMARY";
export const GET_LAUNCH_SUMMARY_SUCCESS = "GET_LAUNCH_SUMMARY_SUCCESS";
export const GET_LAUNCH_SUMMARY_FAILED = "GET_LAUNCH_SUMMARY_FAILED";
export const GET_LAUNCH_SUMMARY_RESET = "GET_LAUNCH_SUMMARY_RESET";

export const CLICK_TO_LIST_REQUESTED = "CLICK_TO_LIST_REQUESTED";
export const CLICK_TO_LIST_SUCCESS = "CLICK_TO_LIST_SUCCESS";
export const CLICK_TO_LIST_FAILED = "CLICK_TO_LIST_FAILED";
export const CLICK_TO_LIST_RESET = "CLICK_TO_LIST_RESET";

export const CLICK_TO_UNLIST_REQUESTED = "CLICK_TO_UNLIST_REQUESTED";
export const CLICK_TO_UNLIST_SUCCESS = "CLICK_TO_UNLIST_SUCCESS";
export const CLICK_TO_UNLIST_FAILED = "CLICK_TO_UNLIST_FAILED";
export const CLICK_TO_UNLIST_RESET = "CLICK_TO_UNLIST_RESET";

export const CHANGE_PASSWORD_REQUESTED = "CHANGE_PASSWORD_REQUESTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

export const PRICING_REQUESTED = "PRICING_REQUESTED";
export const PRICING_SUCCESS = "PRICING_SUCCESS";
export const PRICING_FAILED = "PRICING_FAILED";
export const PRICING_RESET = "PRICING_RESET";

export const INVITE_USER_REQUESTED = "INVITE_USER_REQUESTED";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILED = "INVITE_USER_FAILED";
export const INVITE_USER_RESET = "INVITE_USER_RESET";

export const INVITE_USER_REQUESTED_ADMIN_PORTAL = "INVITE_USER_REQUESTED_ADMIN_PORTAL";
export const INVITE_USER_SUCCESS_ADMIN_PORTAL = "INVITE_USER_SUCCESS_ADMIN_PORTAL";
export const INVITE_USER_FAILED_ADMIN_PORTAL = "INVITE_USER_FAILED_ADMIN_PORTAL";
export const INVITE_USER_RESET_ADMIN_PORTAL = "INVITE_USER_RESET_ADMIN_PORTAL";

export const GET_ALL_USERS_REQUESTED = "GET_ALL_USERS_REQUESTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const GET_ALL_USERS_RESET = "GET_ALL_USERS_RESET";

export const GET_ALL_USERS_REQUESTED_ADMIN_PORTAL = "GET_ALL_USERS_REQUESTED_ADMIN_PORTAL";
export const GET_ALL_USERS_SUCCESS_ADMIN_PORTAL = "GET_ALL_USERS_SUCCESS_ADMIN_PORTAL";
export const GET_ALL_USERS_FAILED_ADMIN_PORTAL = "GET_ALL_USERS_FAILED_ADMIN_PORTAL";
export const GET_ALL_USERS_RESET_ADMIN_PORTAL = "GET_ALL_USERS_RESET_ADMIN_PORTAL";

export const REINVITE_USER_REQUESTED_ADMIN_PORTAL = "REINVITE_USER_REQUESTED_ADMIN_PORTAL";
export const REINVITE_USER_SUCCESS_ADMIN_PORTAL = "REINVITE_USER_SUCCESS_ADMIN_PORTAL";
export const REINVITE_USER_FAILED_ADMIN_PORTAL = "REINVITE_USER_FAILED_ADMIN_PORTAL";
export const REINVITE_USER_RESET_ADMIN_PORTAL = "REINVITE_USER_RESET_ADMIN_PORTAL";

export const GET_ALL_USERS_REQUESTED_AGGREGATOR = "GET_ALL_USERS_REQUESTED_AGGREGATOR";
export const GET_ALL_USERS_SUCCESS_AGGREGATOR = "GET_ALL_USERS_SUCCESS_AGGREGATOR";
export const GET_ALL_USERS_FAILED_AGGREGATOR = "GET_ALL_USERS_FAILED_AGGREGATOR";
export const GET_ALL_USERS_RESET_AGGREGATOR = "GET_ALL_USERS_RESET_AGGREGATOR";

export const REINVITE_USER_REQUESTED_AGGREGATOR = "REINVITE_USER_REQUESTED_AGGREGATOR";
export const REINVITE_USER_SUCCESS_AGGREGATOR = "REINVITE_USER_SUCCESS_AGGREGATOR";
export const REINVITE_USER_FAILED_AGGREGATOR = "REINVITE_USER_FAILED_AGGREGATOR";
export const REINVITE_USER_RESET_AGGREGATOR = "REINVITE_USER_RESET_AGGREGATOR";

export const DELETE_USER_REQUESTED_AGGREGATOR = "DELETE_USER_REQUESTED_AGGREGATOR";
export const DELETE_USER_SUCCESS_AGGREGATOR = "DELETE_USER_SUCCESS_AGGREGATOR";
export const DELETE_USER_FAILED_AGGREGATOR = "DELETE_USER_FAILED_AGGREGATOR";
export const DELETE_USER_RESET_AGGREGATOR = "DELETE_USER_RESET_AGGREGATOR";

export const INVITE_USER_REQUESTED_AGGREGATOR = "INVITE_USER_REQUESTED_AGGREGATOR";
export const INVITE_USER_SUCCESS_AGGREGATOR = "INVITE_USER_SUCCESS_AGGREGATOR";
export const INVITE_USER_FAILED_AGGREGATOR = "INVITE_USER_FAILED_AGGREGATOR";
export const INVITE_USER_RESET_AGGREGATOR = "INVITE_USER_RESET_AGGREGATOR";

export const REINVITE_USER_REQUESTED = "REINVITE_USER_REQUESTED";
export const REINVITE_USER_SUCCESS = "REINVITE_USER_SUCCESS";
export const REINVITE_USER_FAILED = "REINVITE_USER_FAILED";
export const REINVITE_USER_RESET = "REINVITE_USER_RESET";

export const DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const DELETE_USER_REQUESTED_ADMIN_PORTAL = "DELETE_USER_REQUESTED_ADMIN_PORTAL";
export const DELETE_USER_SUCCESS_ADMIN_PORTAL = "DELETE_USER_SUCCESS_ADMIN_PORTAL";
export const DELETE_USER_FAILED_ADMIN_PORTAL = "DELETE_USER_FAILED_ADMIN_PORTAL";
export const DELETE_USER_RESET_ADMIN_PORTAL = "DELETE_USER_RESET_ADMIN_PORTAL";

export const SET_BILLING_USER_PASSWORD_REQUESTED = "SET_BILLING_USER_PASSWORD_REQUESTED";
export const SET_BILLING_USER_PASSWORD_SUCCESS = "SET_BILLING_USER_PASSWORD_SUCCESS";
export const SET_BILLING_USER_PASSWORD_FAILED = "SET_BILLING_USER_PASSWORD_FAILED";

export const SET_BILLING_USER_PASSWORD_REQUESTED_ADMIN_PORTAL = "SET_BILLING_USER_PASSWORD_REQUESTED_ADMIN_PORTAL";
export const SET_BILLING_USER_PASSWORD_SUCCESS_ADMIN_PORTAL = "SET_BILLING_USER_PASSWORD_SUCCESS_ADMIN_PORTAL";
export const SET_BILLING_USER_PASSWORD_FAILED_ADMIN_PORTAL = "SET_BILLING_USER_PASSWORD_FAILED_ADMIN_PORTAL";

export const UPDATE_CARRIER_PROFILE = "UPDATE_CARRIER_PROFILE_REQUESTED";
export const UPDATE_CARRIER_PROFILE_SUCCESS = "UPDATE_CARRIER_PROFILE_SUCCESS";
export const UPDATE_CARRIER_PROFILE_FAILED = "UPDATE_CARRIER_PROFILE_FAILED";
export const UPDATE_CARRIER_PROFILE_RESET = "UPDATE_CARRIER_PROFILE_RESET";

export const UPDATE_CARRIER_PROFILE_ADMIN_PORTAL = "UPDATE_CARRIER_PROFILE_REQUESTED_ADMIN_PORTAL";
export const UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL = "UPDATE_CARRIER_PROFILE_SUCCESS_ADMIN_PORTAL";
export const UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL = "UPDATE_CARRIER_PROFILE_FAILED_ADMIN_PORTAL";
export const UPDATE_CARRIER_PROFILE_RESET_ADMIN_PORTAL = "UPDATE_CARRIER_PROFILE_RESET_ADMIN_PORTAL";

export const CARRIER_GET_SUBMITTED_BRANDS_REQESTED = "CARRIER_GET_SUBMITTED_BRANDS_REQESTED";
export const CARRIER_GET_SUBMITTED_BRANDS_SUCCESS = "CARRIER_GET_SUBMITTED_BRANDS_SUCCESS";
export const CARRIER_GET_SUBMITTED_BRANDS_FAILED = "CARRIER_GET_SUBMITTED_BRANDS_FAILED";
export const CARRIER_GET_SUBMITTED_BRANDS_RESET = "CARRIER_GET_SUBMITTED_BRANDS_RESET";

export const CARRIER_GET_REJECTED_BRANDS_REQESTED = "CARRIER_GET_REJECTED_BRANDS_REQESTED";
export const CARRIER_GET_REJECTED_BRANDS_SUCCESS = "CARRIER_GET_REJECTED_BRANDS_SUCCESS";
export const CARRIER_GET_REJECTED_BRANDS_FAILED = "CARRIER_GET_REJECTED_BRANDS_FAILED";
export const CARRIER_GET_REJECTED_BRANDS_RESET = "CARRIER_GET_REJECTED_BRANDS_RESET";

export const CARRIER_GET_APPROVED_BRANDS_REQESTED = "CARRIER_GET_APPROVED_BRANDS_REQESTED";
export const CARRIER_GET_APPROVED_BRANDS_SUCCESS = "CARRIER_GET_APPROVED_BRANDS_SUCCESS";
export const CARRIER_GET_APPROVED_BRANDS_FAILED = "CARRIER_GET_APPROVED_BRANDS_FAILED";
export const CARRIER_GET_APPROVED_BRANDS_RESET = "CARRIER_GET_APPROVED_BRANDS_RESET";
export const GET_SUBMITTED_BOTS_FOR_CARRIER = "GET_SUBMITTED_BOTS_FOR_CARRIER";

export const GET_SUBMITTED_BOTS_FOR_CARRIER_SUCCESS = "GET_SUBMITTED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_SUBMITTED_BOTS_FOR_CARRIER_FAILED = "GET_SUBMITTED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_SUBMITTED_BOTS_FOR_CARRIER_RESET = "GET_SUBMITTED_BOTS_FOR_CARRIER_RESET";

export const GET_APPROVED_BOTS_FOR_CARRIER = "GET_APPROVED_BOTS_FOR_CARRIER";
export const GET_APPROVED_BOTS_FOR_CARRIER_SUCCESS = "GET_APPROVED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_APPROVED_BOTS_FOR_CARRIER_FAILED = "GET_APPROVED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_APPROVED_BOTS_FOR_CARRIER_RESET = "GET_APPROVED_BOTS_FOR_CARRIER_RESET";

export const GET_LIST_OF_AGGREGATOR = "GET_LIST_OF_AGGREGATOR";
export const GET_LIST_OF_AGGREGATOR_SUCCESS = "GET_LIST_OF_AGGREGATOR_SUCCESS";
export const GET_LIST_OF_AGGREGATOR_FAILED = "GET_LIST_OF_AGGREGATOR_FAILED";
export const GET_LIST_OF_AGGREGATOR_RESET = "GET_LIST_OF_AGGREGATOR_RESET";

export const GET_REJECTED_BOTS_FOR_CARRIER = "GET_REJECTED_BOTS_FOR_CARRIER";
export const GET_REJECTED_BOTS_FOR_CARRIER_SUCCESS = "GET_REJECTED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_REJECTED_BOTS_FOR_CARRIER_FAILED = "GET_REJECTED_BOTS_FOR_CARRIER_SUCCESS";
export const GET_REJECTED_BOTS_FOR_CARRIER_RESET = "GET_REJECTED_BOTS_FOR_CARRIER_RESET";
export const APPROVE_OR_REJECT_CARRIER_BOTS = "APPROVE_OR_REJECT_CARRIER_BOTS";

export const APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS = "APPROVE_OR_REJECT_CARRIER_BOTS_SUCCESS";
export const APPROVE_OR_REJECT_CARRIER_BOTS_FAILED = "APPROVE_OR_REJECT_CARRIER_BOTS_FAILED";
export const APPROVE_OR_REJECT_CARRIER_BOTS_RESET = "APPROVE_OR_REJECT_CARRIER_BOTS_RESET";

export const APPROVE_OR_REJECT_CARRIER_BRANDS_REQUESTED = "APPROVE_OR_REJECT_CARRIER_BRANDS_REQUESTED";
export const APPROVE_OR_REJECT_CARRIER_BRANDS_SUCCESS = "APPROVE_OR_REJECT_CARRIER_BRANDS_SUCCESS";
export const APPROVE_OR_REJECT_CARRIER_BRANDS_FAILED = "APPROVE_OR_REJECT_CARRIER_BRANDS_FAILED";
export const APPROVE_OR_REJECT_CARRIER_BRANDS_RESET = "APPROVE_OR_REJECT_CARRIER_BRANDS_RESET";

export const GET_SUBMITTED_AGGREGATORS_FOR_CARRIER = "GET_SUBMITTED_AGGREGATORS_FOR_CARRIER";
export const GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS = "GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_SUCCESS";
export const GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED = "GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_FAILED";
export const GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_RESET = "GET_SUBMITTED_AGGREGATORS_FOR_CARRIER_RESET";

export const GET_APPROVED_AGGREGATORS_FOR_CARRIER = "GET_APPROVED_AGGREGATORS_FOR_CARRIER";
export const GET_APPROVED_AGGREGATORS_FOR_CARRIER_SUCCESS = "GET_APPROVED_AGGREGATORS_FOR_CARRIER_SUCCESS";
export const GET_APPROVED_AGGREGATORS_FOR_CARRIER_FAILED = "GET_APPROVED_AGGREGATORS_FOR_CARRIER_FAILED";
export const GET_APPROVED_AGGREGATORS_FOR_CARRIER_RESET = "GET_APPROVED_AGGREGATORS_FOR_CARRIER_RESET";

export const GET_REJECTED_AGGREGATORS_FOR_CARRIER = "GET_REJECTED_AGGREGATORS_FOR_CARRIER";
export const GET_REJECTED_AGGREGATORS_FOR_CARRIER_SUCCESS = "GET_REJECTED_AGGREGATORS_FOR_CARRIER_SUCCESS";
export const GET_REJECTED_AGGREGATORS_FOR_CARRIER_FAILED = "GET_REJECTED_AGGREGATORS_FOR_CARRIER_FAILED";
export const GET_REJECTED_AGGREGATORS_FOR_CARRIER_RESET = "GET_REJECTED_AGGREGATORS_FOR_CARRIER_RESET";

export const APPROVE_OR_REJECT_CARRIER_AGGREGATOR = "APPROVE_OR_REJECT_CARRIER_AGGREGATOR";
export const APPROVE_OR_REJECT_CARRIER_AGGREGATOR_SUCCESS = "APPROVE_OR_REJECT_CARRIER_AGGREGATOR_SUCCESS";
export const APPROVE_OR_REJECT_CARRIER_AGGREGATOR_FAILED = "APPROVE_OR_REJECT_CARRIER_AGGREGATOR_FAILED";
export const APPROVE_OR_REJECT_CARRIER_AGGREGATOR_RESET = "APPROVE_OR_REJECT_CARRIER_AGGREGATOR_RESET";

export const GET_AGGREGATOR_INFO = "GET_AGGREGATOR_INFO";
export const GET_AGGREGATOR_INFO_SUCCESS = "GET_AGGREGATOR_INFO_SUCCESS";
export const GET_AGGREGATOR_INFO_FAILED = "GET_AGGREGATOR_INFO_FAILED";
export const GET_AGGREGATOR_INFO_RESET = "GET_AGGREGATOR_INFO_RESET";

export const DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS = "DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_REQESTED";
export const DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_SUCCESS = "DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_SUCCESS";
export const DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_FAILED = "DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_FAILED";
export const DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_RESET = "DOWNLOAD_REPORT_FOR_SUBMITTED_BOTS_RESET";

//==========================ADMIN ACTION TYPE =========================================================

export const GET_SUBMITTED_BOTS_FOR_ADMIN = "GET_SUBMITTED_BOTS_FOR_ADMIN";
export const GET_SUBMITTED_BOTS_FOR_ADMIN_RESET = "GET_SUBMITTED_BOTS_FOR_ADMIN_RESET";
export const GET_SUBMITTED_BOTS_FOR_ADMIN_SUCCESS = "GET_SUBMITTED_BOTS_FOR_ADMIN_SUCCESS";
export const GET_SUBMITTED_BOTS_FOR_ADMIN_FAILED = "GET_SUBMITTED_BOTS_FOR_ADMIN_FAILED";

export const GET_APPROVED_BOTS_FOR_ADMIN = "GET_APPROVED_BOTS_FOR_ADMIN";
export const GET_APPROVED_BOTS_FOR_ADMIN_RESET = "GET_APPROVED_BOTS_FOR_ADMIN_RESET";
export const GET_APPROVED_BOTS_FOR_ADMIN_SUCCESS = "GET_APPROVED_BOTS_FOR_ADMIN_SUCCESS";
export const GET_APPROVED_BOTS_FOR_ADMIN_FAILED = "GET_APPROVED_BOTS_FOR_ADMIN_FAILED";

export const GET_REJECTED_BOTS_FOR_ADMIN = "GET_REJECTED_BOTS_FOR_ADMIN";
export const GET_REJECTED_BOTS_FOR_ADMIN_RESET = "GET_REJECTED_BOTS_FOR_ADMIN_RESET";
export const GET_REJECTED_BOTS_FOR_ADMIN_SUCCESS = "GET_REJECTED_BOTS_FOR_ADMIN_SUCCESS";
export const GET_REJECTED_BOTS_FOR_ADMIN_FAILED = "GET_REJECTED_BOTS_FOR_ADMIN_FAILED";

export const GET_FORWARDED_BOTS_FOR_ADMIN = "GET_FORWARDED_BOTS_FOR_ADMIN";
export const GET_FORWARDED_BOTS_FOR_ADMIN_RESET = "GET_FORWARDED_BOTS_FOR_ADMIN_RESET";
export const GET_FORWARDED_BOTS_FOR_ADMIN_SUCCESS = "GET_FORWARDED_BOTS_FOR_ADMIN_SUCCESS";
export const GET_FORWARDED_BOTS_FOR_ADMIN_FAILED = "GET_FORWARDED_BOTS_FOR_ADMIN_FAILED";

export const APPROVE_OR_REJECT_BOTS_FOR_ADMIN = "APPROVE_OR_REJECT_BOTS_FOR_ADMIN";
export const APPROVE_OR_REJECT_BOTS_FOR_ADMIN_RESET = "APPROVE_OR_REJECT_BOTS_FOR_ADMIN_RESET";
export const APPROVE_OR_REJECT_BOTS_FOR_ADMIN_SUCCESS = "APPROVE_OR_REJECT_BOTS_FOR_ADMIN_SUCCESS";
export const APPROVE_OR_REJECT_BOTS_FOR_ADMIN_FAILED = "APPROVE_OR_REJECT_BOTS_FOR_ADMIN_FAILED";

export const BOTS_HISTORY_FOR_ADMIN = "BOTS_HISTORY_FOR_ADMIN";
export const BOTS_HISTORY_FOR_ADMIN_RESET = "BOTS_HISTORY_FOR_ADMIN_RESET";
export const BOTS_HISTORY_FOR_ADMIN_SUCCESS = "BOTS_HISTORY_FOR_ADMIN_SUCCESS";
export const BOTS_HISTORY_FOR_ADMIN_FAILED = "BOTS_HISTORY_FOR_ADMIN_FAILED";

export const FORWARD_BOTS_FOR_ADMIN = "FORWARD_BOTS_FOR_ADMIN";
export const FORWARD_BOTS_FOR_ADMIN_RESET = "FORWARD_BOTS_FOR_ADMIN_RESET";
export const FORWARD_BOTS_FOR_ADMIN_SUCCESS = "FORWARD_BOTS_FOR_ADMIN_SUCCESS";
export const FORWARD_BOTS_FOR_ADMIN_FAILED = "FORWARD_BOTS_FOR_ADMIN_FAILED";

export const GET_DOTGO_SUMMARY = "GET_DOTGO_SUMMARY";
export const GET_DOTGO_SUMMARY_SUCCESS = "GET_DOTGO_SUMMARY_SUCCESS";
export const GET_DOTGO_SUMMARY_FAILED = "GET_DOTGO_SUMMARY_FAILED";
export const GET_DOTGO_SUMMARY_RESET = "GET_DOTGO_SUMMARY_RESET";

export const ADMIN_GET_SUBMITTED_BRANDS_REQESTED = "ADMIN_GET_SUBMITTED_BRANDS_REQESTED";
export const ADMIN_GET_SUBMITTED_BRANDS_SUCCESS = "ADMIN_GET_SUBMITTED_BRANDS_SUCCESS";
export const ADMIN_GET_SUBMITTED_BRANDS_FAILED = "ADMIN_GET_SUBMITTED_BRANDS_FAILED";
export const ADMIN_GET_SUBMITTED_BRANDS_RESET = "ADMIN_GET_SUBMITTED_BRANDS_RESET";

export const ADMIN_GET_REJECTED_BRANDS_REQESTED = "ADMIN_GET_REJECTED_BRANDS_REQESTED";
export const ADMIN_GET_REJECTED_BRANDS_SUCCESS = "ADMIN_GET_REJECTED_BRANDS_SUCCESS";
export const ADMIN_GET_REJECTED_BRANDS_FAILED = "ADMIN_GET_REJECTED_BRANDS_FAILED";
export const ADMIN_GET_REJECTED_BRANDS_RESET = "ADMIN_GET_REJECTED_BRANDS_RESET";

export const ADMIN_GET_APPROVED_BRANDS_REQESTED = "ADMIN_GET_APPROVED_BRANDS_REQESTED";
export const ADMIN_GET_APPROVED_BRANDS_SUCCESS = "ADMIN_GET_APPROVED_BRANDS_SUCCESS";
export const ADMIN_GET_APPROVED_BRANDS_FAILED = "ADMIN_GET_APPROVED_BRANDS_FAILED";
export const ADMIN_GET_APPROVED_BRANDS_RESET = "ADMIN_GET_APPROVED_BRANDS_RESET";

export const PENDING_APPROVAL_BRANDS_REQESTED = "PENDING_APPROVAL_BRANDS_REQESTED";
export const PENDING_APPROVAL_BRANDS_SUCCESS = "PENDING_APPROVAL_BRANDS_SUCCESS";
export const PENDING_APPROVAL_BRANDS_FAILED = "PENDING_APPROVAL_BRANDS_FAILED";
export const PENDING_APPROVAL_BRANDS_RESET = "PENDING_APPROVAL_BRANDS_RESET";

export const APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED = "APPROVE_OR_REJECT_ADMIN_BRANDS_REQUESTED";
export const APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS = "APPROVE_OR_REJECT_ADMIN_BRANDS_SUCCESS";
export const APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED = "APPROVE_OR_REJECT_ADMIN_BRANDS_FAILED";
export const APPROVE_OR_REJECT_ADMIN_BRANDS_RESET = "APPROVE_OR_REJECT_ADMIN_BRANDS_RESET";

export const FORWARD_BRAND_FOR_ADMIN = "FORWARD_BRAND_FOR_ADMIN";
export const FORWARD_BRAND_FOR_ADMIN_SUCCESS = "FORWARD_BRAND_FOR_ADMIN_SUCCESS";
export const FORWARD_BRAND_FOR_ADMIN_FAILED = "FORWARD_BRAND_FOR_ADMIN_FAILED";
export const FORWARD_BRAND_FOR_ADMIN_RESET = "FORWARD_BRAND_FOR_ADMIN_RESET";

export const BRAND_HISTORY_FOR_ADMIN_REQUESTED = "BRAND_HISTORY_FOR_ADMIN_REQUESTED";
export const BRAND_HISTORY_FOR_ADMIN_RESET = "BRAND_HISTORY_FOR_ADMIN_RESET";
export const BRAND_HISTORY_FOR_ADMIN_SUCCESS = "BRAND_HISTORY_FOR_ADMIN_SUCCESS";
export const BRAND_HISTORY_FOR_ADMIN_FAILED = "BRAND_HISTORY_FOR_ADMIN_FAILED";

/**------------------------ Onboarding Portal -------------------- */
export const CREATE_BOT_AGENT = "CREATE_BOT_AGENT";
export const CREATE_BOT_AGENT_SUCCESS = "CREATE_BOT_AGENT_SUCCESS";
export const CREATE_BOT_AGENT_FAILED = "CREATE_BOT_AGENT_FAILED";
export const CREATE_BOT_AGENT_RESET = "CREATE_BOT_AGENT_RESET";

export const BOT_AGENT_SUBMIT_CARRIERS = "BOT_AGENT_SUBMIT_CARRIERS";
export const BOT_AGENT_SUBMIT_CARRIERS_SUCCESS = "BOT_AGENT_SUBMIT_CARRIERS_SUCCESS";
export const BOT_AGENT_SUBMIT_CARRIERS_FAILED = "BOT_AGENT_SUBMIT_CARRIERS_FAILED";
export const BOT_AGENT_SUBMIT_CARRIERS_RESET = "BOT_AGENT_SUBMIT_CARRIERS_RESET";

export const LOAD_BOT_REVIEWS = "LOAD_BOT_REVIEWS";
export const LOAD_BOT_REVIEWS_SUCCESS = "LOAD_BOT_REVIEWS_SUCCESS";
export const LOAD_BOT_REVIEWS_FAILED = "LOAD_BOT_REVIEWS_FAILED";

export const SEND_BOT_REVIEWS = "SEND_BOT_REVIEWS";
export const SEND_BOT_REVIEWS_SUCCESS = "SEND_BOT_REVIEWS_SUCCESS";
export const SEND_BOT_REVIEWS_FAILED = "SEND_BOT_REVIEWS_SUCCESS";

export const GET_SUBMITTED_AGGREGATOR_FOR_ADMIN = "GET_SUBMITTED_AGGREGATOR_FOR_ADMIN";
export const GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_RESET = "GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_RESET";
export const GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_SUCCESS = "GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_FAILED = "GET_SUBMITTED_AGGREGATOR_FOR_ADMIN_FAILED";

export const GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN = "GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN";
export const GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_RESET = "GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_RESET";
export const GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS = "GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED = "GET_SUBMITTED_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED";

export const GET_TEMPLATES_FOR_ADMIN = "GET_TEMPLATES_FOR_ADMIN";
export const GET_TEMPLATES_FOR_ADMIN_RESET = "GET_TEMPLATES_FOR_ADMIN_RESET";
export const GET_TEMPLATES_FOR_ADMIN_SUCCESS = "GET_TEMPLATES_FOR_ADMIN_SUCCESS";
export const GET_TEMPLATES_FOR_ADMIN_FAILED = "GET_TEMPLATES_FOR_ADMIN_FAILED";

export const GET_APPROVED_AGGREGATOR_FOR_ADMIN = "GET_APPROVED_AGGREGATOR_FOR_ADMIN";
export const GET_APPROVED_AGGREGATOR_FOR_ADMIN_RESET = "GET_APPROVED_AGGREGATOR_FOR_ADMIN_RESET";
export const GET_APPROVED_AGGREGATOR_FOR_ADMIN_SUCCESS = "GET_APPROVED_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const GET_APPROVED_AGGREGATOR_FOR_ADMIN_FAILED = "GET_APPROVED_AGGREGATOR_FOR_ADMIN_FAILED";

export const GET_REJECTED_AGGREGATOR_FOR_ADMIN = "GET_REJECTED_AGGREGATOR_FOR_ADMIN";
export const GET_REJECTED_AGGREGATOR_FOR_ADMIN_RESET = "GET_REJECTED_AGGREGATOR_FOR_ADMIN_RESET";
export const GET_REJECTED_AGGREGATOR_FOR_ADMIN_SUCCESS = "GET_REJECTED_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const GET_REJECTED_AGGREGATOR_FOR_ADMIN_FAILED = "GET_REJECTED_AGGREGATOR_FOR_ADMIN_FAILED";

export const GET_FORWARDED_AGGREGATOR_FOR_ADMIN = "GET_FORWARDED_AGGREGATOR_FOR_ADMIN";
export const GET_FORWARDED_AGGREGATOR_FOR_ADMIN_RESET = "GET_FORWARDED_AGGREGATOR_FOR_ADMIN_RESET";
export const GET_FORWARDED_AGGREGATOR_FOR_ADMIN_SUCCESS = "GET_FORWARDED_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const GET_FORWARDED_AGGREGATOR_FOR_ADMIN_FAILED = "GET_FORWARDED_AGGREGATOR_FOR_ADMIN_FAILED";

export const APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN = "APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN";
export const APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_RESET = "APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_RESET";
export const APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_SUCCESS = "APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_FAILED = "APPROVE_OR_REJECT_AGGREGATOR_FOR_ADMIN_FAILED";

export const APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN = "APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN";
export const APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_RESET = "APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_RESET";
export const APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS = "APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED = "APPROVE_OR_REJECT_DOTGO_AGGREGATOR_FOR_ADMIN_FAILED";

export const AGGREGATOR_HISTORY_FOR_ADMIN = "AGGREGATOR_HISTORY_FOR_ADMIN";
export const AGGREGATOR_HISTORY_FOR_ADMIN_RESET = "AGGREGATOR_HISTORY_FOR_ADMIN_RESET";
export const AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS = "AGGREGATOR_HISTORY_FOR_ADMIN_SUCCESS";
export const AGGREGATOR_HISTORY_FOR_ADMIN_FAILED = "AGGREGATOR_HISTORY_FOR_ADMIN_FAILED";

export const FORWARD_AGGREGATOR_FOR_ADMIN = "FORWARD_AGGREGATOR_FOR_ADMIN";
export const FORWARD_AGGREGATOR_FOR_ADMIN_RESET = "FORWARD_AGGREGATOR_FOR_ADMIN_RESET";
export const FORWARD_AGGREGATOR_FOR_ADMIN_SUCCESS = "FORWARD_AGGREGATOR_FOR_ADMIN_SUCCESS";
export const FORWARD_AGGREGATOR_FOR_ADMIN_FAILED = "FORWARD_AGGREGATOR_FOR_ADMIN_FAILED";

export const GET_PRICE_PAGE_DETAILS = "GET_PRICE_PAGE_DETAILS";
export const GET_PRICE_PAGE_DETAILS_SUCCESS = "GET_PRICE_PAGE_DETAILS_SUCCESS";
export const GET_PRICE_PAGE_DETAILS_FAILED = "GET_PRICE_PAGE_DETAILS_FAILED";
export const GET_PRICE_PAGE_DETAILS_RESET = "GET_PRICE_PAGE_DETAILS_RESET";

export const GET_ALL_CARRIER_AND_COUNTRY_LIST_REQUESTED = "GET_CARRIER_AND_COUNTRY_LIST_REQUESTED";
export const GET_ALL_CARRIER_AND_COUNTRY_LIST_SUCCESS = "GET_CARRIER_AND_COUNTRY_LIST_SUCCESS";
export const GET_ALL_CARRIER_AND_COUNTRY_LIST_FAILED = "GET_CARRIER_AND_COUNTRY_LIST_FAILED";

export const STORE_SELECTED_CARRIER = "STORE_SELECTED_CARRIER";
export const REMOVE_SELECTED_CARRIER = "REMOVE_SELECTED_CARRIER";
export const STORE_ALL_SELECTED_CARRIER = "STORE_ALL_SELECTED_CARRIER";
export const REMOVE_ALL_SELECTED_CARRIER = "REMOVE_ALL_SELECTED_CARRIER";

export const SUBMIT_PRE_SELECTED_CARRIER = "SUBMIT_PRE_SELECTED_CARRIER";
// export const SUBMIT_PRE_SELECTED_CARRIER_SUCCESS = "SUBMIT_PRE_SELECTED_CARRIER_SUCCESS"
// export const SUBMIT_PRE_SELECTED_CARRIER_FAILED = "SUBMIT_PRE_SELECTED_CARRIER_FAILED"

// export const CREATE_RCS_BOT = "CREATE_RCS_BOT";
// export const CREATE_RCS_BOT_SUCCESS = "CREATE_RCS_BOT_SUCCESS";
// export const CREATE_RCS_BOT_FAILED = "CREATE_RCS_BOT_FAILED";
// export const CREATE_RCS_BOT_RESET = "CREATE_RCS_BOT_RESET";

export const ADD_RICH_TEMPLATE = "ADD_RICH_TEMPLATE";
export const ADD_RICH_TEMPLATE_SUCCESS = "ADD_RICH_TEMPLATE_SUCCESS";
export const ADD_RICH_TEMPLATE_FAILED = "ADD_RICH_TEMPLATE_FAILED";
export const ADD_RICH_TEMPLATE_RESET = "ADD_RICH_TEMPLATE_RESET";

export const SUBMIT_PRE_SELECTED_CARRIER_SUCCESS = "SUBMIT_PRE_SELECTED_CARRIER_SUCCESS";
export const SUBMIT_PRE_SELECTED_CARRIER_FAILED = "SUBMIT_PRE_SELECTED_CARRIER_FAILED";

export const CREATE_RCS_BOT = "CREATE_RCS_BOT";
export const CREATE_RCS_BOT_SUCCESS = "CREATE_RCS_BOT_SUCCESS";
export const CREATE_RCS_BOT_FAILED = "CREATE_RCS_BOT_FAILED";
export const CREATE_RCS_BOT_RESET = "CREATE_RCS_BOT_RESET";

export const BOT_CONSOLE_INFO = "BOT_CONSOLE_INFO";
export const BOT_CONSOLE_INFO_SUCCESS = "BOT_CONSOLE_INFO_SUCCESS";
export const BOT_CONSOLE_INFO_FAILED = "BOT_CONSOLE_INFO_FAILED";

export const SUBMIT_SELECTED_CARRIER = "SUBMIT_SELECTED_CARRIER";
export const SUBMIT_SELECTED_CARRIER_FAILED = "SUBMIT_SELECTED_CARRIER_FAILED";
export const SUBMIT_SELECTED_CARRIER_SUCCESS = "SUBMIT_SELECTED_CARRIER_SUCCESS";

export const STORE_VERIFICATION_DETAILS = "STORE_VERIFICATION_DETAILS";
export const STORE_VERIFICATION_DETAILS_SUCCESS = "STORE_VERIFICATION_DETAILS_SUCCESS";
export const STORE_VERIFICATION_DETAILS_FAILED = "STORE_VERIFICATION_DETAILS_FAILED";

export const FETCH_GEO_LOCATION = "FETCH_GEO_LOCATION";
export const FETCH_GEO_LOCATION_SUCCESS = "FETCH_GEO_LOCATION_SUCCESS";
export const FETCH_GEO_LOCATION_FAILED = "FETCH_GEO_LOCATION_FAILED";
export const BOT_CONSOLE_GET_TEMPLATES = "BOT_CONSOLE_GET_TEMPLATES";
export const BOT_CONSOLE_GET_TEMPLATES_SUCCESS = "BOT_CONSOLE_GET_TEMPLATES_SUCCESS";
export const BOT_CONSOLE_GET_TEMPLATES_FAILED = "BOT_CONSOLE_GET_TEMPLATES_FAILED";
export const BOT_SCREENS = "BOT_SCREENS";
export const BOT_SCREENS_SUCCESS = "BOT_SCREENS_SUCCESS";
export const BOT_SCREENS_FAILED = "BOT_SCREENS_FAILED";
export const BOT_SCREENS_RESET = "BOT_SCREENS_RESET";

export const UPDATE_LISTING = "UPDATE_LISTING";
export const UPDATE_LISTING_SUCCESS = "UPDATE_LISTING_SUCCESS";
export const UPDATE_LISTING_FAILED = "UPDATE_LISTING_FAILED";
export const UPDATE_LISTING_RESET = "UPDATE_LISTING_RESET";
// ADMIN Listing Actions
export const ADMIN_SUBMITTED_BOTS_FOR_LISTING = "ADMIN_SUBMITTED_BOTS_FOR_LISTING";
export const ADMIN_SUBMITTED_BOTS_FOR_LISTING_RESET = "ADMIN_SUBMITTED_BOTS_FOR_LISTING_RESET";
export const ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS = "ADMIN_SUBMITTED_BOTS_FOR_LISTING_SUCCESS";
export const ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED = "ADMIN_SUBMITTED_BOTS_FOR_LISTING_FAILED";

export const ADMIN_REJECT_BOT_FOR_LISTING = "ADMIN_REJECT_BOT_FOR_LISTING";
export const ADMIN_REJECT_BOT_FOR_LISTING_RESET = "ADMIN_REJECT_BOT_FOR_LISTING_RESET";
export const ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS = "ADMIN_REJECT_BOT_FOR_LISTING_SUCCESS";
export const ADMIN_REJECT_BOT_FOR_LISTING_FAILED = "ADMIN_REJECT_BOT_FOR_LISTING_FAILED";

export const ADMIN_APPROVE_BOT_FOR_LISTING = "ADMIN_APPROVE_BOT_FOR_LISTING";
export const ADMIN_APPROVE_BOT_FOR_LISTING_RESET = "ADMIN_APPROVE_BOT_FOR_LISTING_RESET";
export const ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS = "ADMIN_APPROVE_BOT_FOR_LISTING_SUCCESS";
export const ADMIN_APPROVE_BOT_FOR_LISTING_FAILED = "ADMIN_APPROVE_BOT_FOR_LISTING_FAILED";

export const ADMIN_GET_BOT_LISTING_HISTORY = "ADMIN_GET_BOT_LISTING_HISTORY";
export const ADMIN_GET_BOT_LISTING_HISTORY_RESET = "ADMIN_GET_BOT_LISTING_HISTORY_RESET";
export const ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS = "ADMIN_GET_BOT_LISTING_HISTORY_SUCCESS";
export const ADMIN_GET_BOT_LISTING_HISTORY_FAILED = "ADMIN_GET_BOT_LISTING_HISTORY_FAILED";
// ADMIN Listing Actions 🔚

// ADMIN Bots Submitted for Verification Actions
export const ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION = "ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION";
export const ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET = "ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET";
export const ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS = "ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS";
export const ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED = "ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED";

export const ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION = "ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION";
export const ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET = "ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET";
export const ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS = "ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS";
export const ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED = "ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED";
// creation approve/reject
export const ADMIN_APPROVE_REJECT_BOT_FOR_CREATION = "ADMIN_APPROVE_REJECT_BOT_FOR_CREATION";
export const ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_RESET = "ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_RESET";
export const ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS = "ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS";
export const ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED = "ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED";

// ADMIN Bots Submitted for Verification Actions 🔚
export const ADMIN_KRCS_CREATION = "ADMIN_KRCS_CREATION";
export const ADMIN_KRCS_CREATION_SUCCESS = "ADMIN_KRCS_CREATION_SUCCESS";
export const ADMIN_KRCS_CREATION_FAILED = "ADMIN_KRCS_CREATION_FAILED";
export const ADMIN_KRCS_CREATION_RESET = "ADMIN_KRCS_CREATION_RESET";

// Admin Insert Agent
export const ADMIN_INSERT_AGENT = "ADMIN_INSERT_AGENT";
export const ADMIN_INSERT_AGENT_SUCCESS = "ADMIN_INSERT_AGENT_SUCCESS";
export const ADMIN_INSERT_AGENT_FAILED = "ADMIN_INSERT_AGENT_FAILED";
export const ADMIN_INSERT_AGENT_RESET = "ADMIN_INSERT_AGENT_RESET";
// Admin Insert MaaP Agent
export const ADMIN_INSERT_MaaP_AGENT = "ADMIN_INSERT_MaaP_AGENT";
export const ADMIN_INSERT_MaaP_AGENT_SUCCESS = "ADMIN_INSERT_MaaP_AGENT_SUCCESS";
export const ADMIN_INSERT_MaaP_AGENT_FAILED = "ADMIN_INSERT_MaaP_AGENT_FAILED";
export const ADMIN_INSERT_MaaP_AGENT_RESET = "ADMIN_INSERT_MaaP_AGENT_RESET";

// Admin: Available MaaPs
export const GET_AVAILABLE_MaaP = "GET_AVAILABLE_MaaP";
export const GET_AVAILABLE_MaaP_SUCCESS = "GET_AVAILABLE_MaaP_SUCCESS";
export const GET_AVAILABLE_MaaP_FAILED = "GET_AVAILABLE_MaaP_FAILED";
export const GET_AVAILABLE_MaaP_RESET = "GET_AVAILABLE_MaaP_RESET";

// Admin: Agent Info
export const GET_AGENT_INFO = "GET_AGENT_INFO";
export const GET_AGENT_INFO_SUCCESS = "GET_AGENT_INFO_SUCCESS";
export const GET_AGENT_INFO_FAILED = "GET_AGENT_INFO_FAILED";
export const GET_AGENT_INFO_RESET = "GET_AGENT_INFO_RESET";

//onBoarding Delete bot Actions
export const DELETE_BOT = "DELETE_BOT";
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS";
export const DELETE_BOT_FAILED = "DELETE_BOT_FAILED";
export const DELETE_BOT_RESET = "DELETE_BOT_RESET";

export const GET_BOT_CREATION_PREVIEW_REQUESTED = "GET_BOT_CREATION_PREVIEW_REQUESTED";
export const GET_BOT_CREATION_PREVIEW_SUCCESS = "GET_BOT_CREATION_PREVIEW_SUCCESS";
export const GET_BOT_CREATION_PREVIEW_FAILED = "GET_BOT_CREATION_PREVIEW_FAILED";
export const GET_BOT_CREATION_PREVIEW_RESET = "GET_BOT_CREATION_PREVIEW_RESET";

export const GET_SINGLE_BRAND_REQUESTED_PREVIEW = "GET_SINGLE_BRAND_REQUESTED_PREVIEW";
export const GET_SINGLE_BRAND_SUCCESS_PREVIEW = "GET_SINGLE_BRAND_SUCCESS_PREVIEW";
export const GET_SINGLE_BRAND_FAILED_PREVIEW = "GET_SINGLE_BRAND_FAILED_PREVIEW";
export const GET_SINGLE_BRAND_RESET_PREVIEW = "GET_SINGLE_BRAND_RESET_PREVIEW";

export const GET_VERIFIED_BOT_DETAILS = "GET_VERIFIED_BOT_DETAILS";
export const GET_VERIFIED_BOT_DETAILS_SUCCESS = "GET_VERIFIED_BOT_DETAILS_SUCCESS";
export const GET_VERIFIED_BOT_DETAILS_FAILED = "GET_VERIFIED_BOT_DETAILS_FAILED";
export const GET_VERIFIED_BOT_DETAILS_RESET = "GET_VERIFIED_BOT_DETAILS_RESET";

//onBoarding Delete Template Actions
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED";
export const DELETE_TEMPLATE_RESET = "DELETE_TEMPLATE_RESET";

//onBoarding Delete bot Actions
export const DELETE_DOCS = "DELETE_DOCS";
export const DELETE_DOCS_SUCCESS = "DELETE_DOCS_SUCCESS";
export const DELETE_DOCS_FAILED = "DELETE_DOCS_FAILED";
export const DELETE_DOCS_RESET = "DELETE_DOCS_RESET";

export const GET_TEST_DEVICES_REQUESTED = "GET_TEST_DEVICES_REQUESTED";
export const GET_TEST_DEVICES_SUCCESS = "GET_TEST_DEVICES_SUCCESS";
export const GET_TEST_DEVICES_FAILED = "GET_TEST_DEVICES_FAILED";
export const GET_TEST_DEVICES_RESET = "GET_TEST_DEVICES_RESET";

export const ADD_TEST_DEVICES_REQUESTED = "ADD_TEST_DEVICES_REQUESTED";
export const ADD_TEST_DEVICES_SUCCESS = "ADD_TEST_DEVICES_SUCCESS";
export const ADD_TEST_DEVICES_FAILED = "ADD_TEST_DEVICES_FAILED";
export const ADD_TEST_DEVICES_RESET = "ADD_TEST_DEVICES_RESET";

export const DELETE_TEST_DEVICES_REQUESTED = "DELETE_TEST_DEVICES_REQUESTED";
export const DELETE_TEST_DEVICES_SUCCESS = "DELETE_TEST_DEVICES_SUCCESS";
export const DELETE_TEST_DEVICES_FAILED = "DELETE_TEST_DEVICES_FAILED";
export const DELETE_TEST_DEVICES_RESET = "DELETE_TEST_DEVICES_RESET";

export const SEND_MESSAGE_REQUESTED = "SEND_MESSAGE_REQUESTED";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
export const SEND_MESSAGE_RESET = "SEND_MESSAGE_RESET";

export const GET_TEMPLATE_DETAILS = "GET_TEMPLATE_DETAILS";
export const GET_TEMPLATE_DETAILS_SUCCESS = "GET_TEMPLATE_DETAILS_SUCCESS";
export const GET_TEMPLATE_DETAILS_FAILED = "GET_TEMPLATE_DETAILS_FAILED";
export const GET_TEMPLATE_DETAILS_RESET = "GET_TEMPLATE_DETAILS_RESET";

export const GET_TEMPLATE_HISTORY = "GET_TEMPLATE_HISTORY";
export const GET_TEMPLATE_HISTORY_SUCCESS = "GET_TEMPLATE_HISTORY_SUCCESS";
export const GET_TEMPLATE_HISTORY_FAILED = "GET_TEMPLATE_HISTORY_FAILED";
export const GET_TEMPLATE_HISTORY_RESET = "GET_TEMPLATE_HISTORY_RESET";

export const UPDATE_RCS_BOT_ONB_REQUESTED = "UPDATE_RCS_BOT_ONB_REQUESTED";
export const UPDATE_RCS_BOT_ONB_SUCCESS = "UPDATE_RCS_BOT_ONB_SUCCESS";
export const UPDATE_RCS_BOT_ONB_FAILED = "UPDATE_RCS_BOT_ONB_FAILED";
export const UPDATE_RCS_BOT_ONB_RESET = "UPDATE_RCS_BOT_ONB_RESET";

export const GET_SINGLE_TEMPLATE_REQUESTED = "GET_SINGLE_TEMPLATE_REQUESTED";
export const GET_SINGLE_TEMPLATE_SUCCESS = "GET_SINGLE_TEMPLATE_SUCCESS";
export const GET_SINGLE_TEMPLATE_FAILED = "GET_SINGLE_TEMPLATE_FAILED";
export const GET_SINGLE_TEMPLATE_RESET = "GET_SINGLE_TEMPLATE_RESET";

export const GET_ADMIN_CAMPAIGN_REQUESTED = "GET_ADMIN_CAMPAIGN_REQUESTED";
export const GET_ADMIN_CAMPAIGN_SUCCESS = "GET_ADMIN_CAMPAIGN_SUCCESS";
export const GET_ADMIN_CAMPAIGN_FAILED = "GET_ADMIN_CAMPAIGN_FAILED";
export const GET_ADMIN_CAMPAIGN_RESET = "GET_ADMIN_CAMPAIGN_RESET";

export const APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN = "APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN";
export const APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_RESET = "APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_RESET";
export const APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_SUCCESS = "APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_SUCCESS";
export const APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_FAILED = "APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN_FAILED";

export const GENERATE_OTP = "GENERATE_OTP";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILED = "GENERATE_OTP_FAILED";
export const GENERATE_OTP_RESET = "GENERATE_OTP_RESET";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILED = "VALIDATE_OTP_FAILED";
export const VALIDATE_OTP_RESET = "VALIDATE_OTP_RESET";

export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED";
export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_SUCCESS = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_SUCCESS";
export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_FAILED = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_FAILED";
export const RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY = "RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY";

export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_REQUESTED = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_REQUESTED";
export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_SUCCESS = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_SUCCESS";
export const GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_FAILED = "GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_FAILED";
export const RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT = "RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT";

//-----------------------------test template Actions---------------------------------
export const TEST_TEMPLATE = "TEST_TEMPLATE";
export const TEST_TEMPLATE_SUCCESS = "TEST_TEMPLATE_SUCCESS";
export const TEST_TEMPLATE_FAILED = "TEST_TEMPLATE_FAILED";
export const TEST_TEMPLATE_RESET = "TEST_TEMPLATE_RESET";

export const TEST_SUMMARY = "TEST_SUMMARY";
export const TEST_SUMMARY_SUCCESS = "TEST_SUMMARY_SUCCESS";
export const TEST_SUMMARY_FAILED = "TEST_SUMMARY_FAILED";
export const TEST_SUMMARY_RESET = "TEST_SUMMARY_RESET";

export const RETEST_TEMPLATE = "RETEST_TEMPLATE";
export const RETEST_TEMPLATE_SUCCESS = "RETEST_TEMPLATE_SUCCESS";
export const RETEST_TEMPLATE_FAILED = "RETEST_TEMPLATE_FAILED";
export const RETEST_TEMPLATE_RESET = "RETEST_TEMPLATE_RESET";

//-----------------------------test template Actions end--------------------------------

export const GET_AGENT_MSG_TYPE_REQUESTED = "GET_AGENT_MSG_TYPE_REQUESTED";
export const GET_AGENT_MSG_TYPE_SUCCESS = "GET_AGENT_MSG_TYPE_SUCCESS";
export const GET_AGENT_MSG_TYPE_FAILED = "GET_AGENT_MSG_TYPE_FAILED";
export const GET_AGENT_MSG_TYPE_RESET = "GET_AGENT_MSG_TYPE_RESET";

export const GET_TEMPLATE_JSON = "GET_TEMPLATE_JSON";
export const GET_TEMPLATE_JSON_SUCCESS = "GET_TEMPLATE_JSON_SUCCESS";
export const GET_TEMPLATE_JSON_FAILED = "GET_TEMPLATE_JSON_FAILED";
export const GET_TEMPLATE_JSON_RESET = "GET_TEMPLATE_JSON_RESET";
